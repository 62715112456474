import { createTheme, ThemeProvider } from "@mui/material";
import { createGlobalStyle, keyframes } from "styled-components";
import {
  MEDIA_LARGE,
  MEDIA_MEDIUM,
  MEDIA_SMALL,
  SIGA_IMAGE_TOP_OFFSET,
  SIGA_MAIN_BLUE,
  WARNING_COLOR,
} from "./constants";
import { PasswordProtect } from "./components/PasswordProtect";
import { AppContent } from "./components/AppContent";
import {
  createHashRouter,
  Navigate,
  RouterProvider,
  useNavigate,
  useParams,
} from "react-router-dom";
import { AppWrapper } from "./components/AppWrapper";
import { IntlProvider } from "react-intl";
import translations from "./translations";
import { TermsAndConditions } from "./components/TermsAndConditions";
import { WarrantyLetter } from "./components/WarrantyLetter";
import CalculationConfirmation from "./components/CalculationConfirmation/CalculationConfirmation";
import { SuccessView } from "./components/SuccessView";
import { HowToUse } from "./components/HowToUse";
import { languages } from "./data/constants";

export type SupportedLanguage = "en" | "fr" | "de";

const IntlWrapper = (props: { children: React.ReactNode }) => {
  const { lang } = useParams();
  const navigate = useNavigate();
  if (!languages.includes(lang || "")) {
    navigate("/de", { replace: true });
  }
  return (
    <IntlProvider
      locale={lang as SupportedLanguage}
      messages={translations[lang as SupportedLanguage]}
    >
      {props.children}
    </IntlProvider>
  );
};

const useLang = () => {
  const { lang } = useParams();
  return lang as SupportedLanguage;
};

// get browser language without the region code
const language = navigator.language.split(/[-_]/)[0];

// const languageToNavigate = languages.includes(language) ? language : "en";
const languageToNavigate = "de";

const router = createHashRouter([
  {
    path: "/",
    element: <Navigate to={`/${languageToNavigate}`} replace />,
  },
  // ----- temporary routes
  {
    path: "/en",
    element: <Navigate to={`/${languageToNavigate}`} replace />,
  },
  {
    path: "/fr",
    element: <Navigate to={`/${languageToNavigate}`} replace />,
  },
  // ---- temporary routes end
  {
    path: "/:lang",
    element: (
      <IntlWrapper>
        <AppWrapper />
        <PasswordProtect />
      </IntlWrapper>
    ),
    children: [
      {
        path: "/:lang",
        element: (
          <IntlWrapper>
            <AppContent />
          </IntlWrapper>
        ),
      },
      {
        path: "/:lang/how-to-use",
        element: (
          <IntlWrapper>
            <HowToUse />
          </IntlWrapper>
        ),
      },
      {
        path: "/:lang/step-2",
        element: (
          <IntlWrapper>
            <CalculationConfirmation />
          </IntlWrapper>
        ),
      },
      {
        path: "/:lang/step-3",
        element: (
          <IntlWrapper>
            <TermsAndConditions />
          </IntlWrapper>
        ),
      },
      {
        path: "/:lang/success",
        element: (
          <IntlWrapper>
            <SuccessView />
            {/* <WarrantyLetter /> */}
          </IntlWrapper>
        ),
      },
    ],
  },
]);

const fadeInAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const GlobalStyle = createGlobalStyle`
  body {
    background: #fff;
    font-family: Arial, Helvetica, "Lucida Grande", sans-serif;
    @media print {
      -webkit-print-color-adjust: exact !important;
    }
  }
  .sticky {
    position: fixed;
    ${MEDIA_LARGE} {
      position: fixed !important;
    }
    ${MEDIA_MEDIUM} {
      right: 32px;
    }
    top: 0;
    margin-top: ${SIGA_IMAGE_TOP_OFFSET}px;
    ${MEDIA_SMALL} {
      margin-top: 70px;
      right: 0;
      border-bottom: 1px solid #dedede;
    }
  }
  html, 
  body {
    margin:0;
    padding:0;
      height: 100%;
  }
  a {
    color: ${SIGA_MAIN_BLUE};
  }
  #root, .App {
    position: relative;
    min-height: calc(100vh - 84px);
  }
  .App {
    padding-bottom: 84px;
  }
  .MuiChip-filledPrimary {
      span {
        color: #fff;
      }
      svg {
        path {
          fill: #fff;
        }
      }
  }
  .panel, .warning-message {
    &.open {
      animation-name: ${fadeInAnimation};
      animation-duration: 0.5s;
      // display: flex;
      height:auto;
      min-height:20px;
      @media print {
        font-size: 10px;
        line-height:14px;
      }
    }
    &.hidden {
      opacity: 0;
      pointer-events: none;
      display: none;
    }
  }
  .MuiButtonBase-root {
    border-color: #C1C7CA !important;
  } 
`;

const theme = createTheme({
  palette: {
    primary: {
      main: SIGA_MAIN_BLUE,
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          fontSize: "20px",
          marginLeft: "-2px",
          color: WARNING_COLOR,
          "&$error": {
            color: WARNING_COLOR,
          },
        },
      },
    },
  },
});

const App = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <RouterProvider router={router} />
  </ThemeProvider>
);

export default App;
