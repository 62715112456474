import axios from "axios";
import { API, DataSection, MainData, SECTION_ID } from "../data/types";
import { MainData as allDataNodes } from "../data/appData";

const p = (n: string) => parseInt(n, 10);
const getNr = (d: MainData, t: string) =>
  Object.keys(d).find((k: string) => d[p(k)].title === t);
const getNrDeep = (d: MainData, t: string) =>
  Object.keys(d).find(
    (k: string) =>
      d[p(k)].title === t || !!d[p(k)].nodes.find((n) => n.title === t)
  );

const getParameters = async (allData: DataSection[], api: API) => {
  const d = allData;
  if (api === API.FLAT_ROOF) {
    const shadeTypeNr = d.findIndex((e) => e.id === SECTION_ID.SHADE);
    const shadeType = shadeTypeNr ? allData[shadeTypeNr] : allData[10];

    const productTypeNr = d.findIndex(
      (e) => e.id === SECTION_ID.VAPOR_CONTROL_LAYER
    );
    const productType = productTypeNr ? allData[productTypeNr] : allData[11];

    const colorNr = d.findIndex(
      (e) => e.id === SECTION_ID.COLOUR_WATERPROOFING
    );
    const color = colorNr ? allData[colorNr] : allData[9];

    const insulationTypeNr = d.findIndex(
      (e) => e.id === SECTION_ID.INSULATION_BETWEEN_RAFTERS
    );
    const insulationType = insulationTypeNr
      ? allData[insulationTypeNr]
      : allData[4];

    const insulationValueNr = d.findIndex(
      (e) => e.id === SECTION_ID.INSULATION_LAYER_THICKNESS
    );
    // "Insulation layer thickness (mm):");
    const insulationValue = insulationValueNr
      ? allData[insulationValueNr]
      : allData[5];

    return await axios.post(
      `${process.env.REACT_APP_BACKEND_HOST}/v2/parameters`,
      {
        productType:
          productType.nodes.find((n) => n.selected)?.value === "Majrex"
            ? "MAJREX"
            : "MAJPELL",
        color: color.nodes.find((n) => n.selected)?.value,
        shade:
          shadeType.nodes.find((n) => n.selected)?.value.toLowerCase() ===
          "no shade"
            ? "No"
            : "Yes",
        shadeType: shadeType.nodes.find((n) => n.selected)?.value,
        insulationType: insulationType.nodes.find((n) => n.selected)?.value,
        insulationValue: insulationValue.nodes.find(
          (n) => n.title.indexOf("Insulation layer thickness") !== -1
        )?.selectValue,
      }
    );
  } else if (api === API.PITCHED_ROOF) {
    const side = d
      ?.find((e) => e.id === SECTION_ID.PITCHED_ROOF_MEMBRANE_SIDE)
      ?.nodes.find((n) => n.selected)?.value;
    const diffusion = d
      ?.find((e) => e.id === SECTION_ID.PITCHED_ROOF_BUILD_UP)
      ?.nodes.find((n) => n.selected)?.value;
    const insulation = d
      .find((e) => e.id === SECTION_ID.PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS)
      ?.nodes.find((n) => n.selected)?.value;
    const insulationThickness = d
      .find((e) => e.id === SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES)
      ?.nodes.find(
        (n) => n.title === "Insulation layer thickness (mm):"
      )?.selectValue;
    const insulationType = d
      .find((e) => e.id === SECTION_ID.PITCHED_ROOF_SEALING_TYPE)
      ?.nodes.find((n) => n.selected)?.value;
    const coveringMaterial = d
      .find((e) => e.id === SECTION_ID.PITCHED_ROOF_COVERING_MATERIAL)
      ?.nodes.find((n) => n.selected)?.value;
    const coveringColor = d
      .find((e) => e.id === SECTION_ID.PITCHED_ROOF_COVERING_COLOR)
      ?.nodes.find((n) => n.selected)?.value;
    const productType = d
      .find((e) => e.id === SECTION_ID.PITCHED_ROOF_VAPOR_CONTROL_LAYER)
      ?.nodes.find((n) => n.selected)?.value;

    return await axios.post(
      `${process.env.REACT_APP_BACKEND_HOST}/v2/pitched_roof_parameters`,
      {
        side,
        diffusion,
        insulation,
        insulationThickness,
        insulationType,
        coveringMaterial,
        coveringColor,
        productType: productType === "Majrex" ? "MAJREX" : "MAJPELL",
      }
    );
  } else if (api === API.PITCHED_ROOF_OUTSIDE_MAJCOAT) {
    const insulationThickness = d
      .find((e) => e.id === SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES_OUTSIDE)
      ?.nodes.find((n) => n.title === "Insulation layer thickness (mm):")
      ?.selectValue?.split(" - ")[0];

    const allInsulationThicknesses =
      allDataNodes
        .find(
          (e) =>
            e.id === SECTION_ID.PITCHED_ROOF_ON_ROOF_MATERIAL_PROPERTIES_OUTSIDE
        )
        ?.nodes.find((n) => n.title === "Insulation layer thickness (mm):")
        ?.childNodes?.map((n) => n.value.split(" - ")[0]) || [];

    const underlayType = d.find(
      (e) => e.id === SECTION_ID.PITCHED_ROOF_WHICH_UNDERLAY_OUTSIDE
    )
      ? d
          .find((e) => e.id === SECTION_ID.PITCHED_ROOF_WHICH_UNDERLAY_OUTSIDE)
          ?.nodes.find((n) => n.selected)?.value
      : "Majcoat 200";

    const allCurrentLambdaValues =
      allDataNodes
        .find(
          (e) =>
            e.id === SECTION_ID.PITCHED_ROOF_ON_ROOF_MATERIAL_PROPERTIES_OUTSIDE
        )
        ?.nodes.find((n) => n.title === "Lambda value λ(w/mK):")
        ?.childNodes?.map((n) => [n.value.split(" - ")[1], n.title]) || [];

    const lambda = d
      .find((e) => e.id === SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES_OUTSIDE)
      ?.nodes.find((n) => n.title === "Lambda value λ(w/mK):")
      ?.selectValue?.split(" - ")[1];

    let vals = await axios.post(
      `${process.env.REACT_APP_BACKEND_HOST}/v2/pitched_roof_majcoat_parameters`,
      {
        underlayType,
      }
    );

    if (!lambda || !insulationThickness) {
      return { data: { error: "Invalid API" } };
    }

    console.log(
      "allLambdaValues",
      allCurrentLambdaValues,
      "selected lambda",
      lambda,
      vals
    );
    Object.keys(vals.data.data).forEach((key) => {
      Object.keys(vals.data.data[key]).forEach((k) => {
        Object.keys(vals.data.data[key][k]).forEach((i) => {
          if (i !== insulationThickness) {
            delete vals.data.data[key][k][i];
          }
        });
      });
    });

    let result: { [key: string]: { [key: string]: boolean } } = {};

    allInsulationThicknesses?.forEach((thickness) => {
      result[thickness] = {};
      allCurrentLambdaValues?.forEach((_lambda) => {
        const lambdaNormalized = Math.round(parseFloat(_lambda[0]) * 1000);
        const labmdaToUse = lambdaNormalized >= 47 ? "47" : "43";
        const previousLambdaNormalized = Math.round(parseFloat(lambda) * 1000);
        const bcData =
          vals.data.data[labmdaToUse][thickness][insulationThickness][
            previousLambdaNormalized >= 40 ? 1 : 0
          ];
        console.log(
          "labmdaToUse",
          labmdaToUse,
          "thickness",
          thickness,
          "previousLambdaNormalized",
          previousLambdaNormalized,
          "bcData",
          vals.data.data[labmdaToUse][thickness][insulationThickness]
        );
        result[thickness][_lambda[1]] = bcData === 1;
      });
    });

    console.log("vals: ", result);
    return { data: { data: result } };
  } else {
    return { data: { error: "Invalid API" } };
  }
};

export default getParameters;
