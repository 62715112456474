export enum SECTION_ID {
  "TYPE_OF_CONSTRUCTION" = "type_of_construction",
  "ROOF_TYPE" = "roof_type",
  "FLAT_ROOF_BUILD_UP" = "flat_roof_build_up",
  "INSULATION_BETWEEN_RAFTERS" = "insulation_between_rafters",
  "INSULATION_LAYER_THICKNESS" = "insulation_layer_thickness",
  "WOODEN_SUPPORT_PANEL" = "wooden_support_panel",
  "WOOD_SUBSTRATE" = "wood_substrate",
  "WATERPROOFING" = "waterproofing",
  "COLOUR_WATERPROOFING" = "colour_waterproofing",
  "SHADE" = "shade",
  "VAPOR_CONTROL_LAYER" = "vapor_control_layer",
  "EXTERNAL_OVER_INSULATION" = "external_over_insulation",

  "PITCHED_ROOF_BUILD_UP" = "pitched_roof_build_up",
  "PITCHED_ROOF_BUILD_UP_OUTSIDE" = "pitched_roof_build_up_outside",
  "PITCHED_ROOF_MEMBRANE_SIDE" = "pitched_roof_membrane_side",
  "PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS" = "pitched_roof_insulation_between_rafters",
  "PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS_OUTSIDE" = "pitched_roof_insulation_between_rafters_outside",
  "PITCHED_ROOF_MATERIAL_PROPERTIES" = "pitched_roof_material_properties",
  "PITCHED_ROOF_MATERIAL_PROPERTIES_OUTSIDE" = "pitched_roof_material_properties_outside",
  "PITCHED_ROOF_SEALING_TYPE" = "pitched_roof_sealing_type",
  "PITCHED_ROOF_COVERING_MATERIAL" = "pitched_roof_covering_material",
  "PITCHED_ROOF_COVERING_COLOR" = "pitched_roof_covering_color",
  "PITCHED_ROOF_SHADE_CAUSED_BY" = "pitched_roof_shade_caused_by",
  "PITCHED_ROOF_VAPOR_CONTROL_LAYER" = "pitched_roof_vapor_control_layer",
  "PITCHED_ROOF_ALIGNMENT" = "pitched_roof_alignment",
  "PITCHED_ROOF_ALIGNMENT_OUTSIDE" = "pitched_roof_alignment_outside",
  "PITCHED_ROOF_WOODEN_SUPPORT_PANEL_OUTSIDE" = "pitched_roof_wooden_support_panel_outside",
  "PITCHED_ROOF_INSULATION_OVER_RAFTERS_OUTSIDE" = "pitched_roof_insulation_over_rafters_outside",
  "PITCHED_ROOF_ON_ROOF_MATERIAL_PROPERTIES_OUTSIDE" = "pitched_roof_on_roof_material_properties_outside",
  "PITCHED_ROOF_ADDITIONAL_UNDERLAYMENT_OUTSIDE" = "pitched_roof_additional_underlayment_outside",
  "PITCHED_ROOF_WHICH_UNDERLAY_OUTSIDE" = "pitched_roof_which_underlay_outside",
  "PITCHED_ROOF_VENTILATED_ROOFING_MATERIAL" = "pitched_roof_ventilated_roofing_material",
  "PITCHED_ROOF_COVERING_COLOR_OUTSIDE" = "pitched_roof_covering_color_outside",
  "PITCHED_ROOF_SHADE_IS_CAUSED_BY_OUTSIDE" = "pitched_roof_shade_is_caused_by_outside",

  "FORM" = "form",
}

export enum BUTTON_TYPE {
  "VISUAL" = 0,
  "SQUARE" = 1,
  "CHECKBOX" = 2,
  "RADIO" = 3,
}

export enum API {
  "FLAT_ROOF" = "flat_roof_api",
  "PITCHED_ROOF" = "pitched_roof_api",
  "PITCHED_ROOF_OUTSIDE_MAJCOAT" = "pitched_roof_outside_majcoat_api",
}

export interface MainData {
  [key: number]: {
    nodes: DataNode[];
    divider?: boolean;
    parents?: DataNode[];
    warning?: WarningMessage[];
    title?: string;
    nr?: number;
    visualNode?: {
      label: string;
      top: string;
      left: string;
    };
    highlightNode?: {
      top: string;
      left: string;
      width: string;
      height: string;
    };
    multipleChoice?: boolean;
    mustSelectAll?: boolean;
    coverImage?: string;
  };
}

export interface WarningMessage {
  message: string;
  rule: string;
  pdf?: { [key: string]: string };
  includeInLetter?: boolean;
}

export interface DataNode {
  id: string;
  title: string;
  parent?: string;
  end?: boolean;
  selected?: boolean;
  buttonType?: BUTTON_TYPE;
  freeType?: boolean;
  childNodes?: DataNode[];
  icon?: string;
  iconPng?: string;
  hoverElementPadding?: string;
  tooltip?: string;
  value: string;
  selectValue?: string;
  disabled?: boolean;
  skipSteps?: number[];
  nextSection?: SECTION_ID;
  disableRules?: {
    sectionId: SECTION_ID;
    nodeId: string;
  }[];
}

export interface DataSection {
  id: SECTION_ID;
  divider?: boolean;
  api?: API;
  paramsFetched?: boolean;
  title?: string;
  nr?: number;
  nodes: DataNode[];
  nextSection?: SECTION_ID;
  warning?: WarningMessage[];
  visualNode?: {
    img: string;
    imgTop?: string;
    label: string;
    top: string;
    left: string;
    width?: string;
    wrapperLeft?: string;
    nodeActive?: boolean;
  };
  highlightNode?: {
    top: string;
    left: string;
    width: string;
    height: string;
  };
  multipleChoice?: boolean;
  mustSelectAll?: boolean;
  sequential?: boolean;
  coverImage?: string;
}

export interface FormField {
  label: string;
  placeholder: string;
  id: string;
  value: string;
  required: boolean;
  type: string;
  disabled?: boolean;
}

export interface SubmitButton {
  title: string;
}

export interface FormData {
  sections: { from: number; to?: number; sectionTitle?: string }[];
  fields: FormField[];
  submitButton: SubmitButton;
}
