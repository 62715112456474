import pitchedRoof from "../assets/pitchedRoof.svg";
import flatRoof from "../assets/flatRoof.svg";
import otherRoof from "../assets/otherRoof.svg";

import { flatRoofSections } from "./flatRoof/flatRoofSections";
import {
  API,
  BUTTON_TYPE,
  DataSection,
  FormField,
  SECTION_ID,
  FormData,
} from "./types";
import { notSupportedMessage } from "./constants";
import { pitchedRoofSections } from "./pitchedRoof/pitchedRoofSections";
import { pitchedRoofBuildUp } from "./pitchedRoof/outside/buildUp";
import { pitchedRoofOpenMajcoatSections } from "./pitchedRoof/outside/majcoat";

const angleNodes = [
  {
    id: "pr_11_1",
    title: "Alignment (worst situation)",
    buttonType: BUTTON_TYPE.CHECKBOX,
    value: "",
    childNodes: [
      {
        id: "pr_11_1_1",
        title: "North",
        value: "North",
        parent: "pr_11_1",
      },
      {
        id: "pr_11_1_2",
        title: "East",
        value: "East",
        parent: "pr_11_1",
      },
      {
        id: "pr_11_1_3",
        title: "South",
        value: "South",
        parent: "pr_11_1",
      },
      {
        id: "pr_11_1_4",
        title: "West",
        value: "West",
        parent: "pr_11_1",
      },
    ],
  },
  {
    id: "pr_11_2",
    title: "Roof pitch",
    buttonType: BUTTON_TYPE.CHECKBOX,
    value: "",
    disabled: true,
    childNodes: [
      {
        id: "pr_11_2_1",
        value: "5° - 9°",
        title: "5° - 9°",
        parent: "pr_11_2",
      },
      {
        id: "pr_11_2_2",
        value: "10° - 19°",
        title: "10° - 19°",
        parent: "pr_11_2",
      },
      {
        id: "pr_11_2_3",
        value: "20° - 29°",
        title: "20° - 29°",
        parent: "pr_11_2",
      },
      {
        id: "pr_11_2_4",
        value: "30° - 39°",
        title: "30° - 39°",
        parent: "pr_11_2",
      },
      {
        id: "pr_11_2_5",
        value: "40° - 49°",
        title: "40° - 49°",
        parent: "pr_11_2",
      },
      {
        id: "pr_11_2_6",
        value: "50° - 59°",
        title: "50° - 59°",
        parent: "pr_11_2",
      },
      {
        id: "pr_11_2_7",
        value: "60° - 69°",
        title: "60° - 69°",
        parent: "pr_11_2",
      },
      {
        id: "pr_11_2_8",
        value: "70° - 79°",
        title: "70° - 79°",
        parent: "pr_11_2",
      },
      {
        id: "pr_11_2_9",
        value: "80° - 89°",
        title: "80° - 89°",
        parent: "pr_11_2",
      },
    ],
  },
];

export const MainData: DataSection[] = [
  {
    id: SECTION_ID.TYPE_OF_CONSTRUCTION,
    title: "Type of construction:",
    divider: false,
    nodes: [
      {
        id: "2_1",
        title: "New construction",
        value: "New construction",
        nextSection: SECTION_ID.ROOF_TYPE,
      },
      {
        id: "2_2",
        title: "Renovation",
        value: "Renovation",
        nextSection: SECTION_ID.ROOF_TYPE,
      },
    ],
  },
  {
    id: SECTION_ID.ROOF_TYPE,
    divider: true,
    title: "Type of roof:",
    nodes: [
      {
        id: "3_1",
        title: "Flat roof (<5°)",
        value: "Flat roof (<5°)",
        icon: flatRoof,
        nextSection: SECTION_ID.FLAT_ROOF_BUILD_UP,
      },
      {
        id: "3_2",
        title: "Pitched roof (>5°)",
        value: "Pitched roof (>5°)",
        icon: pitchedRoof,
        nextSection: SECTION_ID.PITCHED_ROOF_MEMBRANE_SIDE,
      },
      {
        id: "3_3",
        title: "Other",
        value: "Other",
        icon: otherRoof,
      },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "3_3",
      },
    ],
  },
  ...pitchedRoofBuildUp,
  ...pitchedRoofOpenMajcoatSections,
  ...pitchedRoofSections,
  ...flatRoofSections,
];

const formFields: FormField[] = [
  {
    label: "Company",
    placeholder: "Company",
    id: "form_company",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "Name / First name",
    placeholder: "Name / First name",
    id: "form_name",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "Street and house number",
    placeholder: "Street and house number",
    id: "form_street",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "Postal code",
    placeholder: "Postal code",
    id: "form_postal",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "City",
    placeholder: "City",
    id: "form_city",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "Country",
    placeholder: "Country",
    id: "form_country",
    value: "DE",
    required: true,
    type: "text",
  },
  {
    label: "Email",
    placeholder: "Email",
    id: "form_email",
    value: "",
    required: true,
    type: "email",
  },
  {
    label: "Phone number",
    placeholder: "Phone number",
    id: "form_phone",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "SIGA Contact Person's Email",
    placeholder: "SIGA Contact Person's Email",
    id: "form_siga_contact_person",
    value: "",
    required: true,
    type: "email",
  },
  // ------ contstruction site data
  {
    label: "Construction site name",
    placeholder: "Construction site name",
    id: "form_construction_site_data_name",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "Builder",
    placeholder: "Builder",
    id: "form_construction_site_data_builder",
    value: "",
    required: false,
    type: "text",
  },
  {
    label: "Street and house number",
    placeholder: "Street and house number",
    id: "form_construction_site_data_street",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "Postal code",
    placeholder: "Postal code",
    id: "form_construction_site_data_postal",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "City",
    placeholder: "City",
    id: "form_construction_site_data_city",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "Country",
    placeholder: "Country",
    id: "form_construction_site_data_country",
    value: "DE",
    required: true,
    disabled: true,
    type: "text",
  },
];

export const formData: FormData = {
  sections: [
    { from: 0, to: 9 },
    {
      sectionTitle: "Construction site address",
      from: 9,
    },
  ],
  fields: formFields,
  submitButton: {
    title: "Continue",
  },
};

export const letterOrder = {
  [API.FLAT_ROOF]: [
    SECTION_ID.TYPE_OF_CONSTRUCTION,
    SECTION_ID.ROOF_TYPE,
    SECTION_ID.FLAT_ROOF_BUILD_UP,
    SECTION_ID.VAPOR_CONTROL_LAYER,
    SECTION_ID.INSULATION_BETWEEN_RAFTERS,
    SECTION_ID.INSULATION_LAYER_THICKNESS,
    SECTION_ID.WOODEN_SUPPORT_PANEL,
    SECTION_ID.WOOD_SUBSTRATE,
    SECTION_ID.EXTERNAL_OVER_INSULATION,
    SECTION_ID.WATERPROOFING,
    SECTION_ID.COLOUR_WATERPROOFING,
    SECTION_ID.SHADE,
  ],
  [API.PITCHED_ROOF]: [
    SECTION_ID.TYPE_OF_CONSTRUCTION,
    SECTION_ID.ROOF_TYPE,
    SECTION_ID.PITCHED_ROOF_BUILD_UP,
    SECTION_ID.PITCHED_ROOF_VAPOR_CONTROL_LAYER,
    SECTION_ID.PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS,
    SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES,
    SECTION_ID.PITCHED_ROOF_SEALING_TYPE,
    SECTION_ID.PITCHED_ROOF_COVERING_MATERIAL,
    SECTION_ID.PITCHED_ROOF_COVERING_COLOR,
    SECTION_ID.PITCHED_ROOF_SHADE_CAUSED_BY,
    SECTION_ID.PITCHED_ROOF_ALIGNMENT,
  ],
  [API.PITCHED_ROOF_OUTSIDE_MAJCOAT]: [
    SECTION_ID.TYPE_OF_CONSTRUCTION,
    SECTION_ID.ROOF_TYPE,
    SECTION_ID.PITCHED_ROOF_ALIGNMENT_OUTSIDE,
    SECTION_ID.PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS_OUTSIDE,
    SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES_OUTSIDE,
    SECTION_ID.PITCHED_ROOF_WOODEN_SUPPORT_PANEL_OUTSIDE,
    SECTION_ID.PITCHED_ROOF_INSULATION_OVER_RAFTERS_OUTSIDE,
    SECTION_ID.PITCHED_ROOF_ADDITIONAL_UNDERLAYMENT_OUTSIDE,
    SECTION_ID.PITCHED_ROOF_WHICH_UNDERLAY_OUTSIDE,
    SECTION_ID.PITCHED_ROOF_ON_ROOF_MATERIAL_PROPERTIES_OUTSIDE,
    SECTION_ID.PITCHED_ROOF_VENTILATED_ROOFING_MATERIAL,
    SECTION_ID.PITCHED_ROOF_COVERING_COLOR_OUTSIDE,
    SECTION_ID.PITCHED_ROOF_SHADE_IS_CAUSED_BY_OUTSIDE,
  ],
};
